<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section class="rejoindre__section">
  <div class="container-fuid g-0">
    <div class="row bg-gray row-usager d-flex justify-content-center">
      <div class="col-lg-9 col-md-10 col-11 d-flex flex-column align-items-center text-center">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <span class="texte">rejoindre le Centre de Réadaptation du Patis Fraux</span>
        </div>
        <!-- <h2>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</h2>
        <p>
          Etablissement sanitaire et médico-social associatif dynamique et porteur de projets, le Centre de
          Réadaptation du Patis Fraux accompagne et prend en charge des personnes en situation de handicap
          afin de favoriser une meilleure autonomie et/ou un retour à l’emploi. <br>
          Ce sont 76 salariés du sanitaire et médico-social qui œuvrent chaque jour auprès des personnes
          accompagnées
        </p> -->
      </div>

      <div class="col-lg-6 card-container">
        <div class="col-lg-9 col-md-10 col-11 d-flex flex-column align-items-center text-center mt-4" style="margin: auto;">
          <div class="title-with-pill">
            <span class="pill-title"></span>
            <span class="texte">Découvrez nos offres actuelles. </span>
          </div>
          <span>Vous ne trouvez pas l’offre qui vous correspond ? Envoyez votre <a style="color: #B6C51F; cursor: pointer;" (click)="openDialog()">candidature spontanée.</a> </span>
        </div>
        <div class="card" *ngFor="let offre of offres">
          <a href="#" [routerLink]="['/nous-rejoindre', offre.slug]" >
            <div class="card-top">
              <span class="date"><i class="far fa-clock"></i> {{ offre.date.date | date : 'd LLLL, y' : '' : 'fr' }}</span>
              <div class="pills">
                <span *ngIf="offre.urgent" class="pills_urgent">Urgent</span>
                 <span class="pills_cdi">{{ offre.categorie }}</span>
              </div>
            </div>
            <div class="card-body">
              <h5 class="card-title">— {{ offre.titre }}</h5>
              <p class="card-text">{{ offre.texteVignette }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
