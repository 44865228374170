<mat-dialog-content class="formulaire-container">
  <form [formGroup]="postulationForm" (ngSubmit)="onSubmit()">
  <div class="row justify-content-lg-between justify-content-center g-3">
    <h4 class="text-center">Déposer une candidature spontanée</h4>
    <div class="col-md-6 input-field">
      <div class="form-floating">
        <input type="text" class="form-control" formControlName="nom" id="floatingNom" placeholder="nom" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }">
        <label for="floatingNom">Nom: *</label>
        <div class="invalid-feedback">
          Vous devez renseigner votre nom.
        </div>
      </div>
    </div>
    <div class="col-md-6 input-field">
      <div class="form-floating">
        <input type="email" class="form-control" formControlName="prenom" id="floatingPrenom" placeholder="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }">
        <label for="floatingPrenom">Prénom: *</label>
        <div class="invalid-feedback">
          Vous devez renseigner votre prénom.
        </div>
      </div>
    </div>
    <div class="col-md-6 input-field">
      <div class="form-floating">
        <input type="email" class="form-control" id="floatingEmail" formControlName="email" placeholder="mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
        <label for="floatingEmail">Email: *</label>
        <div class="invalid-feedback">
          Vous devez renseigner votre email.
        </div>
      </div>
    </div>
    <div class="col-md-6 input-field">
      <div class="form-floating">
        <input type="tel" class="form-control" id="floatingTel" formControlName="telephone" placeholder="tel" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }">
        <label for="floatingTel">Téléphone: *</label>
        <div class="invalid-feedback">
          Vous devez renseigner votre téléphone.
        </div>
      </div>
    </div>

    <div class="upload-btn">
      <label for="formFileCV" class="form-label">CV (format PDF - obligatoire)*</label>
      <input class="form-control" type="file" id="formFileCV" formControlName="cv" (change)="handleFileInputCV($event)" [ngClass]="{ 'is-invalid': submitted && f.cv.errors }">
      <div class="invalid-feedback">
        Vous devez renseigner votre nom.
      </div>

    </div>
    <div class="upload-btn">
      <label for="formFileLettre" class="form-label" >Lettre de motivation (format PDF)</label>
      <input class="form-control" formControlName="lettre" type="file" id="formFileLettre" (change)="handleFileInputLettre($event)">
    </div>

    <div class="form-check">
      <input class="form-check-input" formControlName="check" type="checkbox" id="gridCheck" [ngClass]="{ 'is-invalid': submitted && f.check.errors }">
      <label class="form-check-label" for="gridCheck">
        En soumettant ce formulaire, j’accepte que les informations saisies dans ce formulaire soient utilisées, exploitées, traitées pour permettre de me recontacter, pour m’envoyer des informations, dans le cadre de la relation commerciale qui découle de cette demande. <a [routerLink]="['/politique-de-confidentialite']">En savoir plus</a>
      </label>
      <div class="invalid-feedback">
        Vous devez cocher la case pour valider.
      </div>
    </div>
    <!-- <p class="text-informations">Ces informations sont destinées au Centre de Réadaptation du Patis Fraux. En application des articles 27 et 34 de la loi « Informatique et libertés » du 6 janvier 1978, vous disposez d’un droit de modification ou de suppression des données vous concernant. Ce droit peut être exercé par courrier électronique en adressant votre demande à accueil@patis-fraux.fr</p> -->
  </div>
  <button class="btn btn-rejoindre mt-3" type="submit">Je dépose mon CV</button>
</form>
</mat-dialog-content>
