<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<section class="donation__section">
  <div class="container-fuid g-0">
    <div class="row d-flex justify-content-center mt-5">
      <div class="col-xxl-5 col-xl-5 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <span class="texte">Donations</span>
        </div>
        <h2>Pourquoi faire un don au Centre de Réadaptation du Patis Fraux ?</h2>
        <p>
          <br>Le Centre de Réadaptation du Patis Fraux est un établissement de santé privé solidaire qui gère des
          activités sanitaires et médico-sociales au service des personnes en situation de handicap.
          <br>Acteur de la santé et de la solidarité en Bretagne, l’Association rassemble depuis 1982 des équipes
          dédiées à la réadaptation fonctionnelle et/ou professionnelle des usagers et patients ainsi qu’à
          l’amélioration de leur autonomie et quotidien.

          <br>Par votre don, vous soutenez les actions entreprises par les professionnels de l’établissement et
          contribuez à développer et à concrétiser des projets innovants au bénéfice des usagers et patients accueillis.
          <br>Vos dons permettent de garantir l’indépendance de nos actions qui ont pour objectifs :
        </p>
        <ul>
          <li>D’acquérir des outils pour le plateau technique spécialisé afin de poursuivre la diversification des mises
            en situation de travail ;
          </li>
          <li>D’acquérir du matériel pour la salle d’Activité Physique Adaptée afin de développer les accompagnements
            proposés ;
          </li>
          <li>De contribuer aux animations et activités nouvelles auprès des usagers de l’Accueil Temporaire, du Foyer
            d’Hébergement pour Adultes Handicapés ou de l’Habitat Inclusif.
          </li>
        </ul>
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-5">
      <div class="col-xxl-5 col-xl-5 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <span class="texte">Comment donner ?</span>
        </div>
        <div class="d-flex flex-row mt-3">
          <div class="card me-2">
            <div class="card-body">
              <h5 class="card-title">Donner en ligne</h5>
              <p class="card-text">
                Vous pouvez réaliser votre don en ligne par carte bancaire sur la plateforme de paiement 100 % sécurisée. <br><br>
              </p>
              <iframe id="haWidget" allowtransparency="true" src="https://www.helloasso.com/associations/centre-de-readaptation-du-patis-fraux/formulaires/1/widget-bouton" style="width: 100%; height: 70px; border: none;"></iframe>
            </div>
          </div>
          <div class="card ms-2">
            <div class="card-body">
              <h5 class="card-title">Donner par chèque</h5>
              <p class="card-text">
                Vous pouvez envoyer votre chèque libellé à l’ordre de « Association Le Patis Fraux ».
                <br>
                <br><i>Association Le Patis Fraux<br>2, Allée Salvador Dali 35770 Vern-sur-Seiche</i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-5">
      <div class="col-xxl-5 col-xl-5 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <span class="texte">Don et avantages fiscaux</span>
        </div>
        <h2></h2>
        <p>
          Le Centre de Réadaptation du Patis Fraux étant reconnu d’utilité publique, un reçu fiscal vous sera envoyé
          après réception de votre don et pourra vous donner droit à des avantages fiscaux.
        </p>
        <div class="d-flex flex-row">
          <div class="card me-2">
            <div class="card-body">
              <h5 class="card-title">Pour un particulier</h5>
              <p class="card-text">
                Votre don est éligible à une réduction d’impôt de 66 % du montant des dons. La réduction s'applique dans
                la limite de 20 % du revenu imposable.
                <br>Pour exemple : Pour un don de 200 € à l’Association Le Patis Fraux, ce don vous coûtera 68 € car
                vous avez le droit à une réduction d'impôt de 132 € (200 € x 66 %).
              </p>
            </div>
          </div>
          <div class="card ms-2">
            <div class="card-body">
              <h5 class="card-title">Pour une entreprise</h5>
              <p class="card-text">
                L'entreprise imposée à l'impôt sur le revenu ou sur les sociétés peut bénéficier d'une réduction
                d'impôts
                60 % du montant du don, sans limite de montant pour les versements de dons effectués.
                <br>La réduction fiscale ne peut cependant pas dépasser 20 000 € ou 0.5 % du chiffre d'affaires annuel
                hors taxe (plafond appliqué à l'ensemble des versements effectués).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center my-5">
      <div class="col-xxl-5 col-xl-5 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <span class="texte">Vous souhaitez plus d’informations ?</span>
        </div>
        <h2></h2>
        <div class="card card-black">
            <div class="card-body">
              <p class="card-text">
                N’hésitez pas à nous envoyer un mail à <a href="mailto:accueil@patisfraux.fr">accueil@patisfraux.fr</a> ou via le formulaire de contact et nous prendrons contact avec vous.
              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</section>
