<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<div class="donations">
  <div class="sparks">
    <i class="fas fa-hand-holding-heart"></i>
  </div>
  <a href="#">
    FAITES UN DON
    <span>DON SÉCURISÉ</span>
  </a>
</div>
<section class="etablissement__section">
  <div class="row row-organigramme pt-5 mt-3 justify-content-center">
    <div class="col-xxl-10 col-xl-4 col-md-10 col-11">
      <div class="title-with-pill">
        <span class="pill-title"></span>
        <h2 class="texte">Association Le Patis Fraux</h2>
      </div>
      <p>
        Acteur du privé solidaire, l’Association Le Patis Fraux, gère des établissements et services
        sanitaires et médico-sociaux sur le champ du handicap et des personnes âgées.<br><br>

        Par l’engagement de ses équipes, l’établissement assure une prise en charge et un
        accompagnement en adéquation avec les besoins et les attentes des personnes en situation
        de fragilité liés à leur handicap et/ou leur âge.
        <br><br>

        L’Association est présente sur deux sites situés à Vern-sur-Seiche et gère :<br>
      </p>
      <ul>
        <li>
          1 établissement de santé privé d'intérêt collectif (ESPIC) de soins médicaux et de réadaptation
        </li>
        <li>
          6 établissements et services médico-sociaux : Accueil Temporaire (Foyer d’Accueil
          Médicalisé et Foyer de Vie), Service de Réadaptation Professionnelle (SRP), EHPAD,
          Foyer d’Hébergement pour Adultes Handicapés (FHAH), Service d’Accompagnement à
          la Vie Sociale (SAVS) et Service d’Accompagnement Médico-Social pour Adultes
          Handicapés (SAMSAH)
        </li>
      </ul>
      <p>
        L’Association Le Patis Fraux porte également une Maison Sport Santé (Patis F’orm), un
        organisme de formation (Patis Learn) ainsi qu’un Habitat Inclusif (Patis Home).<br/><br/>

        L’Association est adhérente à la Fédération des Etablissements Hospitaliers et d’Aide à la
        Personne à but non lucratif (FEHAP), au Réseau GRAAL 35, à l’Union Régionale Interfédérale
        des Organismes Privés Sanitaires et Sociaux (URIOPSS Bretagne) et à la FAGERH (fédère les
        établissements de réadaptation professionnelle).
      </p>
    </div>
  </div>

  <div class="row row-organigramme pt-5 mt-3 justify-content-center">
    <div class="col-xxl-10 col-xl-4 col-md-10 col-11">
      <div class="title-with-pill">
        <span class="pill-title"></span>
        <h2 class="texte">L’organisation</h2>
      </div>
      <p>
        L’Association Le Patis Fraux est administré par un Conseil d’Administration qui
        détermine les orientations politiques de l’Association. Les membres du bureau du
        Conseil d’Administration préparent et mettent en œuvre les orientations votées en
        Conseil d’Administration.<br><br>
        Le Conseil d’Administration est composé de 19 membres élus lors de l’Assemblée
        Générale.<br><br>
        Le Directeur a pour mission d’animer et de mettre en œuvre la politique de
        l’Association sur l’ensemble de ses activités avec le soutien de l’équipe de Direction
        et des salariés des différents services sanitaires, médico-sociaux et
        d’accompagnement.
      </p>
      <button class="btn btn-telecharger shadow-none" (click)="openPdf()">Organigramme de l’établissement</button>
    </div>
  </div>

  <div class="row row-organigramme pt-5 mt-3 justify-content-center">
    <div class="col-xxl-10 col-xl-4 col-md-10 col-11">
      <div class="title-with-pill">
        <span class="pill-title"></span>
        <h2 class="texte">Les Valeurs</h2>
      </div>
        <p>
          <li>Ecoute et respect des personnes</li> <br>

          C’est le socle d’un accompagnement global et de soins prenant en compte les différentes
          dimensions de la personne. Cela permet d’élaborer avec chacun.e, un projet personnalisé
          professionnel et/ou de vie qui soit adapté, bientraitant et favorisant l’autonomie. <br><br>

          <li>La solidarité active</li> <br>

          Faire ensemble permet d’aller plus loin, de contribuer davantage à la reconnaissance de la
          personne en situation de handicap, de participer activement au changement de regard de la
          société sur le handicap. <br><br>

          <li>L’esprit d’ouverture et le partenariat</li> <br>

          L’Association ne peut pas tout faire seule. Le travail avec d’autres acteurs du champ
          sanitaire, médico-social et social, tout comme le lien avec le monde économique et politique
          permet de se questionner et de confronter les points de vue au service des personnes
          accompagnées.<br><br>

          <li>L’innovation et la créativité</li> <br>

          L’Association est agile, créative et tournée vers l’avenir. Par l’innovation, elle entend faire
          évoluer les pratiques professionnelles et faire progresser les modalités de réponse, dans le
          respect de la dignité, aux besoins des personnes.<br><br>
        </p>
    </div>
  </div>

</section>
