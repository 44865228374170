<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<div class="container-fluid g-0 mention-legale">
    <div class="row justify-content-center">
        <div class="col-xxl-8 col-10">
            <div class="title-with-pill mb-4">
                <span class="pill-title"></span>
                <h2 class="texte">Politique de confidentialité</h2>
            </div>

            <p>
              A l’occasion de l’utilisation du site https://www.patisfraux.fr, peuvent être recueillies : l’URL des liens par
              l’intermédiaire desquels l’utilisateur a accédé au site et le fournisseur d’accès de l’utilisateur.
              Afin de mieux vous renseigner, nous mesurons le nombre de pages consultées, le nombre de visites ainsi
              que la fréquence de retour des internautes grâce aux solutions de Google Analytics et Matomo. Elles sont
              conservées durant 14 mois dans les bases. Elles ne permettent néanmoins pas de retracer l'identité de la
              personne qui a consulté le site.
              <br> <br>
              La présente Politique de confidentialité a pour objet de vous présenter les traitements de données à
              caractère personnel mis en œuvre.
              Le Centre de Réadaptation du Patis Fraux s’engage en tout état de cause à collecter uniquement les
              données strictement nécessaires à leur finalité. Elles sont traitées de manière transparente, confidentielle
              et sécurisée.
              <br> <br>
              Les finalités des traitements sont : <br>
              Traiter les candidatures transmises en réponse aux emplois proposés par le Centre de Réadaptation du
              Patis Fraux. Répondre aux questions posées sur nos activités par le biais du formulaire de contact
              Les données collectées peuvent être : <br>
              Des données d’identification (nom, prénom, courriel, numéro de téléphone,) dans le cas de l’utilisation de
              notre formulaire de contact. <br>
              Des données d’identification et relatives à votre parcours professionnel (diplômes, expérience
              professionnelles), dans le cadre d’un dépôt de CV. <br>
              Conformément aux dispositions du RGPD et à celles de la loi Informatique et liberté dans sa version en
              vigueur, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données
              personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre
              d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être
              envoyée.
              <br> <br>
              Aucune information personnelle de l’utilisateur du site n’est publiée, échangée, transférée, donnée ou
              vendue sur un support quelconque à des tiers. <br>
              Seule l’hypothèse du rachat du Centre de Réadaptation du Patis Fraux et de ses droits permettrait la
              transmission des informations recueillies à l'acquéreur. <br>
              Les bases de données sont protégées par les dispositions du même RGPD. <br> <br>
              Coordonnées du DPO : dpo@patisfraux.fr ou par courrier Centre de Réadaptation du Patis Fraux - 2, allée
              Salvador Dali – 35770 Vern-sur-Seiche.
            </p>
        </div>
    </div>
</div>
