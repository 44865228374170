import { Component, OnInit } from '@angular/core';
import { Partenaire } from '../_models/partenaire';
import { PartenaireService } from '../_services/partenaire.service';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-partenaire',
  templateUrl: './partenaire.component.html',
  styleUrls: ['./partenaire.component.scss']
})
export class PartenaireComponent implements OnInit {

  infoBandeau = {
    'image' : './../../assets/bandeau-etablissement.png',
    'titre' : 'Partenaires',
    'breadcrumb' : 'ACCUEIL > Partenaires'
  }

  partenaires : Partenaire[] = [];
  nbPartenaire : number = 0;

  constructor(private partenaireService: PartenaireService, private metadataService: MetadataServiceService) {
    this.partenaireService.getPartenaires().subscribe(
      (partenaires) => {
        this.partenaires = partenaires;
        this.nbPartenaire = this.partenaires.length;
      }
    );
  }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
      description: 'Page de présentation des partenaires du Centre de Réadaptation du Patis Fraux'
    });
  }

}
