<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section class="actualites__section">
  <div class="container-fuid g-0">
    <div class="row bg-gray row-usager d-flex justify-content-center">
      <div class="col-lg-9 col-md-10 col-11 d-flex flex-column align-items-center text-center">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <span class="texte">Actualités</span>
        </div>
        <h2>Retrouvez toutes les actualités du Centre de Réadaptation du Patis Fraux.</h2>
    </div>
  </div>
  </div>
  <div class="row bg-gray row-actu" *ngIf="nbActu; else elseBlock">
    <div class="col-lg-4 col-md-12" *ngFor="let actu of actualites">
      <div class="card">
        <a href="#" [routerLink]="['/actualites', actu.slug]" >
          <div style="background-image: url({{actu.urlImage}});" class="card-img-top"></div>
          <img class="puce" src="../../assets/puce-texte.png">
          <div class="card-body">
            <h5 class="card-title"><span>—</span> {{ actu.titre }}</h5>
            <p class="card-text">
              {{ actu.texte_vignette }}
            </p>
            <p class="text-muted">{{ actu.dateCreation.date | date : 'd LLLL, y' : '' : 'fr' }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
  <ng-template #elseBlock>
    <div class="col-12 bg-gray text-center py-5">
      Aucune actualités pour le moment.
    </div>
  </ng-template>
</section>
