<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<div class="container-fluid g-0 mention-legale">
    <div class="row justify-content-center">
        <div class="col-xxl-8 col-10">
            <div class="title-with-pill mb-4">
                <span class="pill-title"></span>
                <h2 class="texte">Politique Cookies</h2>
            </div>
            <h3>Qu’est-ce qu’un cookie?</h3>
            <p>
                Un cookie est un fichier texte placé de manière temporaire dans la mémoire du PC de l’Utilisateur (cookie de “session”), ou stocké de manière permanente sur le disque dur (cookie “persistant”), par un serveur de pages Web.<br>
                Les cookies ne peuvent pas exécuter de programmes ni introduire de virus sur l’ordinateur. Les cookies sont attribués de manière unique et ne peuvent être lus que par un serveur web du domaine qui les a envoyés.<br>
                Nous utilisons des “cookies” afin de personnaliser et optimiser la navigation et le temps en ligne.<br>
                Vous avez la possibilité d’accepter ou de refuser à tout moment les cookies en modifiant les paramètres
                de votre navigateur web. Cependant, le refus des cookies peut obstruer la performance et influencer de manière négative la navigation sur le site patisfraux.fr.<br>
                Si notre Politique sur les Cookies venait à être modifiée, elle serait publiée sur le site dès son applicabilité.
            </p>
            <div class="title-with-pill mt-5 mb-4">
                <span class="pill-title"></span>
                <h2 class="texte">Formulaire de contact</h2>
            </div>
            <p>
                En poursuivant cette action, vous acceptez que les informations recueillies sur ce formulaire soient enregistrées dans un fichier informatisé détenu par le Centre de Réadaptation du Patis Fraux dans le but de traiter votre demande.<br>
                Elles seront conservées pendant 14 mois dans notre fichier prospect, et ne seront pas communiquées à des tiers.<br>
                Pour exercer vos droits listés dans la Politique de Confidentialité, vous pouvez écrire au DPO du Centre de Réadaptation du Patis Fraux en précisant l’objet de votre demande.
            </p>
            <div class="title-with-pill mt-5 mb-4">
                <span class="pill-title"></span>
                <h2 class="texte">Mentions légales</h2>
            </div>
            <p>
                Il est précisé aux utilisateurs du site <a href="https://www.patisfraux.fr">https://www.patisfraux.fr</a>, l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :<br><br>

                Propriétaire :<br>
                Centre de Réadaptation du Patis Fraux<br>
                Responsable de la publication : Matthieu Dubois <br>
                Le site a été réalisé avec l’agence 11h10, <a href="https://www.agence-11h10.fr/">https://www.agence-11h10.fr/</a><br>
                Les photos sont issues du Centre de Réadaptation du Patis Fraux.<br><br>
                Hébergeur : OVH
            </p>
            <h3>Conditions générales d’utilisation</h3>
            <p>
                L’utilisation du site <a href="https://www.patisfraux.fr">https://www.patisfraux.fr</a> implique l’acceptation pleine et entière des conditions générales d’utilisation suivantes.<br>
                Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois nécessaire.<br>
                Le site est mis à jour régulièrement ; Il a pour objet de fournir une information concernant les activités du Centre de Réadaptation du Patis Fraux, sans que cette information soit forcément exhaustive.<br>
                Toutes les informations indiquées sont indicatives, et sont susceptibles d’évoluer.<br>
                Nous ne pouvons pas être tenu pour responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.<br>
                Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable au Centre de Réadaptation du Patis Fraux.<br>
                Les liens hypertextes présents sur le site en direction d’autres ressources Internet, ne sauraient engager la responsabilité du Centre de Réadaptation du Patis Fraux.<br><br>

                Tout litige en relation avec l’utilisation du site est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Rennes.
            </p>
            <!-- <h3>Politique de confidentialité :</h3>
            <p>
                A l’occasion de l’utilisation du site <a href="https://www.patisfraux.fr">https://www.patisfraux.fr</a>, peuvent être recueillies : l’URL des liens par l’intermédiaire desquels l’utilisateur a accédé au site et le fournisseur d’accès de l’utilisateur.<br>
                Afin de mieux vous renseigner, nous mesurons le nombre de pages consultées, le nombre de visites ainsi que la fréquence de retour des internautes grâce aux solutions de Google Analytics et Matomo. Elles sont conservées durant 14 mois dans les bases. Elles ne permettent néanmoins pas de retracer l'identité de la personne qui a consulté le site.<br>
                La présente Politique de confidentialité a pour objet de vous présenter les traitements de données à caractère personnel mis en œuvre.<br>
                Le Centre de Réadaptation du Patis Fraux s’engage en tout état de cause à collecter uniquement les données strictement nécessaires à leur finalité. Elles sont traitées de manière transparente, confidentielle et sécurisée.<br>
                Les finalités des traitements sont :<br>
                Traiter les candidatures transmises en réponse aux emplois proposés par le Centre de Réadaptation du Patis Fraux. Répondre aux questions posées sur nos activités par le biais du formulaire de contact<br>
                Les données collectées peuvent être :<br>
                Des données d’identification (nom, prénom, courriel, numéro de téléphone,) dans le cas de l’utilisation de notre formulaire de contact<br>
                Des données d’identification et relatives à votre parcours professionnel (diplômes, expérience professionnelles), dans le cadre d’un dépôt de CV.<br><br>

                Conformément aux dispositions du RGPD et à celles de la loi Informatique et liberté dans sa version en vigueur, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.<br>
                Aucune information personnelle de l’utilisateur du site n’est publiée, échangée, transférée, donnée ou vendue sur un support quelconque à des tiers. <br>
                Seule l’hypothèse du rachat du Centre de Réadaptation du Patis Fraux et de ses droits permettrait la transmission des informations recueillies à l'acquéreur.<br>
                Les bases de données sont protégées par les dispositions du même RGPD.<br><br>
                Coordonnées du DPO : dpo@patisfraux.fr ou par courrier Centre de Réadaptation du Patis Fraux - 2, allée Salvador Dali – 35770 Vern-sur-Seiche.
            </p> -->
            <div class="title-with-pill mt-5 mb-4">
                <span class="pill-title"></span>
                <h2 class="texte">Pour le dépôt de candidature possible sur le site</h2>
            </div>
            <p>
                Les données personnelles collectées dans le cadre de ce recrutement ont vocation à être utilisées pour ce recrutement et celui-ci seulement. <br>
                Les données collectées pourront être réutilisées pour d’autres recrutements au sein du Centre de Réadaptation du Patis Fraux pendant 1 an. Elles sont conservées en clair par nos soins durant cette période. Néanmoins, il est conseillé de postuler à tout offre susceptible de correspondre à vos compétences.<br><br>

                Elles ne sont en aucun cas cédées à un tiers.<br>
                Conformément aux dispositions légales, vous disposez d’un droit d’accès, de rectification, de suppression et d’opposition pour motif légitime. Vous pouvez les exercer, sous réserve de la fourniture d’une pièce justificative d’identité, auprès du Service Ressources Humaines du Centre de Réadaptation du PATIS FRAUX, aux coordonnées suivantes : 2 allée Salvador Dali – 35770 Vern-sur-Seiche.
            </p>
        </div>
    </div>
</div>
