<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section class="patient-usager__section">
  <div class="container-fuid g-0">
    <div class="row d-flex justify-content-center mt-5">
      <div class="row bg-gray row-usager d-flex justify-content-center">
        <!-- <div class="col-xxl-4 col-xl-4 col-md-10 col-11">
          <div class="title-with-pill">
            <span class="pill-title"></span>
            <h2 class="texte">Livret d’accueil</h2>
          </div>
          <p>
            Votre séjour concerne:
          </p>
          <li>Service de Soins Médicaux et de Réadaptation</li>
          <li>Service de Réadaptation Professionnelle</li>
          <li>Service d’Accompagnement à la Vie Sociale</li>
          <li>Foyer d’Hébergement pour Adultes Handicapés</li>
          <li>Accueil Temporaire TempHau</li>
          <li>Service d’Accompagnement Médico-social pour Adultes Handicapés</li>
        </div> -->
        <div class="col-xxl-4 ms-xl-5 col-xl-4 col-md-10 col-11">
          <div class="red-arrow-container">
            <!-- <a [href]="doc.url" *ngFor="let doc of docFormation"><img src="./../../assets/puce-texte.png"><span>{{ doc.titre }}</span></a> -->
          </div>
        </div>
      </div>
    </div>

    <div class="row row-organigramme pt-5 mt-3 justify-content-center">
      <div class="col-lg-9 col-md-11 d-flex flex-column ml-3">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <h2 class="texte">Relation avec les usagers</h2>
        </div>
        <h3><li class="subtitle">Le Conseil de la Vie Sociale</li></h3> <br>
        <p>
          Un Conseil de la Vie Sociale est une instance d’expression et de participation des personnes
          des services médico-sociaux du Centre de Réadaptation du Patis Fraux : Service de
          Réadaptation Professionnelle, Service d’Accompagnement à la Vie Sociale, Foyer
          d’Hébergement pour Adultes Handicapés, Accueil Temporaire et Service d’Accompagnement
          Médico-social pour Adultes Handicapés. <br><br>

          Le Conseil de la Vie Sociale est défini par la loi du 2 janvier 2002 à l&#39;article L311-6 du code
          de l&#39;action sociale et des familles qui renforce le droit des usagers en leur permettant
          d’exprimer leurs besoins et attentes au sein d’une instance dédiée. Le décret du 25 avril
          2022, applicable au 1er janvier 2023, renforce le rôle du Conseil de la Vie Sociale en
          apportant des modifications dans sa composition, son fonctionnement et ses compétences. <br><br>

          Le Conseil de la Vie Sociale rend des avis et fait des propositions sur toutes questions liées
          au fonctionnement de l’établissement ou du service. <br><br>

          Il peut s'agir : <br>
        <li>de l’organisation du service au quotidien ;</li>
        <li>des animations proposées ;</li>
        <li>de l’élaboration du projet d’établissement ou du service en particulier sur le volet de
          la prévention et de la lutte contre la maltraitance.</li> <br>

        Le Conseil de la Vie Sociale est composé au moins : <br>
        <li>d’un représentant des familles ou des représentants légaux ;</li>
        <li>de deux représentants des personnes accompagnées ;</li>
        <li>d’un représentant légal de l’établissement ou la personne désignée à cet effet ;</li>
        <li>d’un représentant des professionnels employés.</li> <br>

        Pour plus d'informations sur le Conseil de la Vie Sociale : <br>
        <a class="link" target="_blank" href="https://www.bretagne.ars.sante.fr/conseil-de-la-vie-sociale-cvs?parent=6021">https://www.bretagne.ars.sante.fr/conseil-de-la-vie-sociale-cvs?parent=6021</a>
        </p>

        <h3><li class="subtitle">La Commission Des Usagers</li></h3> <br>
        <p>
          La Commission des Usagers concerne les activités sanitaires au Centre de Réadaptation du
          Patis Fraux, et sa mission principale est de veiller au respect des droits des usagers et de
          faciliter leurs démarches afin qu’ils puissent exprimer leurs difficultés. <br><br>

          La Commission des Usagers a plusieurs missions : <br>
        <li>Contribuer à l’amélioration de la politique d’accueil et de prise en charge des patients
          et de leur famille ;</li>
        <li>S’assurer du respect des droits des patients ;</li>
        <li>Faire des propositions pour améliorer l’accueil et la prise en charge des patients et de
          leurs proches ;</li>
        <li>Examiner les plaintes et réclamations ne présentant pas de recours gracieux ou
          juridictionnel.</li><br>

        Les membres de droit de cette instance sont : <br>
        <li>Le représentant légal de l’établissement ou la personne désignée à cet effet ;</li>
        <li>Le médecin médiateur et son suppléant ;</li>
        <li>Le médiateur non-médecin et son suppléant ;</li>
        <li>Deux représentants des usagers et leurs suppléants ;</li> <br>

        Les coordonnées des membres sont disponibles dans le livret d’accueil du Service de Soins
        Médicaux et de Réadaptation. Vous pouvez communiquer directement avec les représentants
        des usagers par email à <a class="link" href="mailto:cdu@patisfraux.fr">cdu@patisfraux.fr</a><br>
        Pour plus d’informations sur la Commission Des Usagers, vous pouvez consulter le <a class="link" target="_blank" href="https://bretagne.france-assos-sante.org">site
        France Assos Santé</a>
        </p>

        <h3><li class="subtitle">Droit des usagers</li></h3>
        <br>
        <p>
        <li>
          <a class="link"  target="_blank" href="https://sante.gouv.fr/IMG/pdf/charte_a4_couleur.pdf">Charte de la personne hospitalisée :</a> cette charte permet de faire
          connaître aux patients leurs droits tels qu’ils sont affirmés dans la loi, notamment
          celle du 4 mars 2002 relative aux droits des malades et à la qualité du système de
          santé.
        </li>
        <li>
          <a class="link" target="_blank" href="https://sante.gouv.fr/IMG/pdf/exe_a4_accue284e.pdf">Charte de la personne accompagnée</a>
        </li>
        <li>Des informations complémentaires sur les droits des usagers sont disponibles dans
          les livrets d’accueil.</li>
        </p>


        <h3><li class="subtitle">Mon Parcours Handicap</li></h3>
        <br>
        <p>
        Mon handicap nécessite la mise en place d’aide humaine et technique pour faciliter ma consultation ou mon séjour ?
        Je peux solliciter le référent handicap du Centre de Réadaptation du Patis Fraux:
          <a class="link"  target="_blank" href="mailto:referent-handicap@patisfraux.fr">referent-handicap@patisfraux.fr</a>
        </p>
      </div>
    </div>

    <div class="row bg-gray d-flex justify-content-center p-5 mt-5">
      <div class="col-xxl-6 col-xl-7 col-md-10">
        <!-- <div class="map-container">
          <div class="map-frame">
            <div id="map"></div>
          </div>
        </div> -->
        <google-map
          height="100%"
          width="100%"
          [center]="{lat: 48.0460636, lng: -1.6123677}"
          [zoom]="11"
          class="google-map"
          [options]="{
          disableDefaultUI: true,
          zoomControl: true,
          scrollwheel: false
          }"
        >
          <map-marker

            [position]="{lat: 48.04542, lng:-1.61027}"
          ></map-marker>
        </google-map>
      </div>
      <div class="col-xxl-4 col-xl-4 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <h2 class="texte">Comment venir au Centre de réadaptation du Patis Fraux ?</h2>
        </div>
        <span>En voiture</span>
        <p>
          Rocade Sud-Est de Rennes (N136), prendre la sortie Vern-sur-Seiche sur l’axe Rennes – Angers. <br><br>

          Au 3ème rond-point, tournez à droite, direction Noyal-Châtillon-sur-Seiche. Immédiatement
          après, prenez la 1ère route à droite, laissez à gauche la Gendarmerie et entrez sur le site du
          Centre de Réadaptation du Patis Fraux.
        </p>

        <span>Transports en commun</span>
        <p>
          Prendre la ligne de Métro A en direction de la Poterie. <br>
          A la station «La Poterie» prendre une des lignes de bus (62, 73 ou 75) en direction de Vern-
          sur-Seiche. <br><br>
          Ligne 62 : descendre à l’arrêt « Fosse gauchère », à 7 min à pied de l’établissement <br><br>
          Ligne 73 et 75 : descendre à l’arrêt « La poste », à 10 min à pied de l’établissement <br>
        </p>

        <span>Depuis la gare SNCF de Rennes</span>
        <p>
          Prendre la ligne de Métro A en direction de la Poterie, puis prendre un bus en reprenant les
          explications ci-dessus. <br><br>

          Quand vous arrivez au Centre de Réadaptation du Patis Fraux, merci de vous présenter à
          l’accueil.
        </p>

      </div>
      <div class="col-lg-10 col-11"></div>
    </div>
  </div>
</section>
